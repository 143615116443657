@import './variables';

// fonts
@font-face {
  font-family: 'Roboto-Light';
  src: url('../public/fonts/Roboto-Light.woff2') format('woff2'),
    url('../public/fonts/Roboto-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-Regular';
  src: url('../public/fonts/Roboto-Regular.woff2') format('woff2'),
    url('../public/fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url('../public/fonts/Roboto-Medium.woff2') format('woff2'),
    url('../public/fonts/Roboto-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-Bold';
  src: url('../public/fonts/Roboto-Bold.woff2') format('woff2'),
    url('../public/fonts/Roboto-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Rubik-v18-latin-regular';
  src: url('../public/fonts/rubik-v18-latin-regular.woff2') format('woff2'),
    url('../public/fonts/rubik-v18-latin-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// animation
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;

  font-family: 'Roboto-Regular', sans-serif;
  -webkit-font-smoothing: antialiased;

  border-width: 0;
  border-style: solid;
  box-sizing: border-box;

  @include media('<=tablet') {
    h1 {
      font-size: 22px;
    }

    h2 {
      font-size: 14px;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .form-item {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    margin-bottom: 15px;
    width: 100%;

    .is-error {
      margin-bottom: 0;
      margin-top: 5px;
      margin-left: 10px;
      font-size: 14px;
      color: $red;
    }

    // generic label style
    label {
      display: block;
      font-size: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $grey;
      margin-bottom: $sp-xxs;
    }

    input {
      max-width: 100%;
    }

    // input type text
    input[type='text'],
    input[type='password'],
    input[data-type='number'],
    input[type='number'],
    select,
    textarea {
      min-width: 100px;
      -webkit-appearance: none;
      margin: 0;
      border-radius: 5px;
      background-color: $white;
      color: $carbon;
      font-size: 16px;
      border: 1px solid $greyMedium;
      height: $btn-height;
      padding: 0 $sp-xs;
      transition: border-color 0.2s;
      &:hover {
        border-color: darken($greyMedium, 20%);
      }
      &:focus,
      &:active {
        border-color: $blue;
        outline: 0;
      }
      &.is-error {
        border-color: $red;
      }
      &:read-only,
      &:disabled {
        background-color: $greyLight;
      }
      &:disabled {
        color: $grey;
      }
    }

    // specific textarea
    textarea {
      padding: $sp-xs $sp-s;
      font-family: $robotoR;
      display: block;
      margin: 0 !important;
    }

    // custom checkbox
    input[type='checkbox'] {
      opacity: 0;
      position: absolute;
      + label {
        color: $carbon;
        font-size: 16px;
        position: relative;
        cursor: pointer;
        text-transform: none;
        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 18px;
          height: 18px;
          flex: 0 0 20px;
          border: 1px solid transparent;
          transition: border-color 0.2s;
        }
        &:before {
          margin-right: $sp-xs;
          border-color: $greyMedium;
          border-radius: 5px;
          background-color: $white;
        }
      }
      + label {
        padding-left: calc(#{$sp-xs} + #{15px});
      }
      &:checked + label:after {
        background: url(../public/icon/check.svg) center/50% no-repeat;
        filter: $f-blueLink;
      }
      &:focus + label:before {
        border-color: $blue;
      }
      &.is-error + label:before {
        border-color: $red;
      }
    }
  }

  button:disabled {
    cursor: default;
    filter: grayscale(1);
  }

  button {
    cursor: pointer;
    margin-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: uppercase;
    border: none;
    border-radius: 4px;
    color: white;

    .spinner {
      display: inline-block;
      width: 10px;
      height: 10px;
      position: relative;
      margin: -1px 0 0 $sp-xs;
      &:after {
        content: ' ';
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 2px solid #fff;
        border-color: $white $white $white transparent;
        animation: spinner 1.2s linear infinite;
      }
    }
  }

  button[type='Submit'] {
    background: linear-gradient(#497397, #464750);
  }

  button[class*='danger'] {
    background: linear-gradient(#af4734, #464750);
  }

  a,
  a:visited,
  a:active {
    text-decoration: none;
    color: $link-color;
    font-weight: bold;
  }

  a:hover {
    text-decoration: underline;
  }

  .required {
    color: $red;
    margin-left: 3px;
  }

  .default-nav {
    width: auto !important;
    background: transparent !important;
  }
}
