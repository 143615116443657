@import '../node_modules/include-media/dist/include-media';

// fixed space variable
$sp-xxs: 5px;
$sp-xs: 10px;
$sp-s: 15px;
$sp-m: 20px;
$sp-l: 30px;
$sp-xl: 40px;
$sp-xxl: 60px;

$title-color: #0f6fb9;
$white: #fff;
$blue: #04246a;
$red: #e1261c;
$grey: #74758c;
$greyMedium: #e6e9f0;
$greyLight: #f1f1f5;
$carbon: #171725;

$link-color: #0f6fb9;

// fixed space variable
$sp-xxs: 5px;
$sp-xs: 10px;
$sp-s: 15px;
$sp-m: 20px;
$sp-l: 30px;
$sp-xl: 40px;
$sp-xxl: 60px;

// fixed size
$btn-height: 42px;

// font
$robotoR: 'Roboto-Regular', sans-serif;
$robotoL: 'Roboto-Light', sans-serif;
$robotoM: 'Roboto-Medium', sans-serif;

// filter colors
$f-blueLink: invert(23%) sepia(50%) saturate(6250%) hue-rotate(220deg)
  brightness(85%) contrast(106%);
